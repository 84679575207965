// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industri-js": () => import("./../src/pages/industri.js" /* webpackChunkName: "component---src-pages-industri-js" */),
  "component---src-pages-karta-js": () => import("./../src/pages/karta.js" /* webpackChunkName: "component---src-pages-karta-js" */),
  "component---src-pages-omoss-js": () => import("./../src/pages/omoss.js" /* webpackChunkName: "component---src-pages-omoss-js" */),
  "component---src-templates-citylist-js": () => import("./../src/templates/citylist.js" /* webpackChunkName: "component---src-templates-citylist-js" */),
  "component---src-templates-company-js": () => import("./../src/templates/company.js" /* webpackChunkName: "component---src-templates-company-js" */)
}

